import React, { FC } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Grid, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import PurpleAccentHero from "../components/PurpleAccentHero"
import CookiesSnackbar from "../components/CookiesSnackbar"
import { useTypography } from "../styles/Typography"
import DiscoverMoreBanner from "../components/DiscoverMoreBanner"

const useStyles = makeStyles({
    titleHeadingGradientWrapper: {
        width: "100%",
        height: "100%",
        background: "linear-gradient(110.11deg, #FFFFFF 38%, rgba(255, 255, 255, 0) 67.89%)"
    },
    titleHeadingText: {
        marginLeft: 100,
        maxWidth: 360,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        height: "100%",

        color: "rgba(0, 0, 0, 0.8)",
        letterSpacing: "0.25px"
    },
    bodyHeader: {
        fontWeight: "bold",
        marginTop: 30
    },
    gridSection: {
        marginTop: 30
    },
    formBox: {
        marginLeft: 20,
        marginRight: 20
    },
    formBoxEnd: {
        marginLeft: 20,
        marginRight: 20,
        marginBottom: 24
    },
    infoBold: {
        fontWeight: "bold",
        textTransform: "uppercase"
    },
    headerTransform: {
        textTransform: "uppercase"
    },
    tableTypographyNote: {
        marginTop: 10
    },
    table: {
        paddingLeft: 5,
        paddingRight: 5
    },
    body: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: 48,
        padding: 24
    },
    bodyContent: {
        width: 600
    },
    bodyTitle: {
        fontFamily: "Ubuntu",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: 32,
        lineHeight: 4,
        letterSpacing: 0.25,
        marginBottom: "16px !important"
    },
    feedback: {
        fontFamily: "Ubuntu",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: 24,
        lineHeight: 1.25,
        letterSpacing: 0.25,
        marginBottom: "16px !important"
    },
    form: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start"
    }
})

const TrialPage: FC = () => {
    const typo = useTypography()
    const classes = useStyles()

    const data = useStaticQuery(graphql`
        query {
            desktopHeroImage: file(relativePath: { eq: "index/home-hero-desktop.jpg" }) {
                childImageSharp {
                    fluid(quality: 75, maxWidth: 2160) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            mobileHeroImage: file(relativePath: { eq: "index/home-hero-mobile.jpg" }) {
                childImageSharp {
                    fluid(quality: 75, maxWidth: 960) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `)

    return (
        <Layout>
            <SEO title="Trial Agreement" />
            <DiscoverMoreBanner/>
            <PurpleAccentHero
                desktopImage={data.desktopHeroImage.childImageSharp.fluid}
                mobileImage={data.mobileHeroImage.childImageSharp.fluid}
                alt="Man smiling in front of his computer in an open office environment"
            >
                <div className={classes.titleHeadingGradientWrapper}>
                    <section className={classes.titleHeadingText}>
                        <h1 className={typo.h4} style={{ marginBottom: 8 }}>
                            Nulia Works
                        </h1>
                        <h2 className={typo.h6} style={{ marginBottom: 32 }}>
                            Realize the potential and unlock the value of Microsoft 365
                        </h2>
                        <p className={typo.subtitle1} style={{ marginBottom: 32 }}>
                            Nulia Works is the pioneering Digital Enablement platform that gets users using their
                            digital skills to realize their potential and unlock the value of Microsoft 365
                        </p>
                    </section>
                </div>
            </PurpleAccentHero>
            <Typography
                variant="h4"
                align="center"
                color="textSecondary"
                className={classes.bodyHeader}
                gutterBottom={true}
                paragraph={true}
            >
                Nulia Trial Agreement
            </Typography>

            <section>
                <Grid container={true} direction="column" className={classes.gridSection} spacing={1}>
                    <Grid item={true} className={classes.formBox}>
                        <Typography variant="subtitle1" color="textSecondary" paragraph={true}>
                            Recitals
                        </Typography>
                    </Grid>
                    <Grid item={true} className={classes.formBox}>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            (A) NULIA ACM, LLC, an Oregon Corporation with its offices located at 2852 Willamette Street, #603 Eugene, OR 97405 USA 
                            (the "Supplier") is a wholly owned subsidiary of Nulia, Inc;
                            Nulia, Inc and its subsidiaries have developed the Services and are the entire legal and
                            beneficial owners of same. The Supplier is an authorized licensor of the Services.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            (B) You (the entity agreeing to these terms, referenced herein as the "Customer") are an
                            End-Customer interested in using the Services.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            (C) Supplier and Customer are together referred to herein as "the Parties," and each
                            individually is referred to as a "Party."
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            (D) Customer wishes to enter, and Supplier has agreed to provide, a no-fee, time-limited
                            trial program to use the Services subject to the terms and conditions of this Agreement.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            (E) Customer acknowledges that, at the end of the Trial Period, the right for the Customer
                            to use the Services will immediately cease unless this Agreement is superseded by a
                            subsequent Services Agreement that may include different payment terms.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            (F) Customer acknowledges that only one Trial Period is available per Customer, and that
                            absent a separate confirmation in writing from Supplier, any subsequent attempt by Customer
                            to re-enter this Agreement to commence a new Trial Period after the expiration of the first
                            Trial Period is rendered void.
                        </Typography>
                    </Grid>

                    <Grid item={true} className={classes.formBox}>
                        <Typography variant="subtitle1" color="textSecondary" paragraph={true}>
                            Agreed terms
                        </Typography>
                    </Grid>
                    <Grid item={true} className={classes.formBox}>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            1. <span className={classes.infoBold}>INTERPRETATION</span>
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            1.1 The following definitions and rules of interpretation apply in this Agreement (including
                            in the Recitals).
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            <span className={classes.infoBold}>Affiliate</span> this software as a service license
                            Agreement, including the contents of the other Schedules attached hereto.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            <span className={classes.infoBold}>Authorized Users</span> those end-users (employees,
                            agents and independent contractors) of the End-Customer who have an Microsoft 365 license
                            assigned to them.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            <span className={classes.infoBold}>Business Day</span> a day other than a Saturday, Sunday
                            or public holiday in Oregon when banks are open for business.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            <span className={classes.infoBold}>Control</span> means that a person possesses, directly or
                            indirectly, the power to direct or cause the direction of the management and policies of the
                            other person (whether through the ownership of voting shares, by contract or otherwise) and
                            “Controls” and “Controlled” shall be interpreted accordingly.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            <span className={classes.infoBold}>Confidential Information</span> information that is
                            proprietary or confidential and is either clearly labelled as such or identified as
                            Confidential Information in Clause 10.5.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            <span className={classes.infoBold}>Customer Data</span> the data inputted by the Customer,
                            or the Supplier on behalf of the Customer or Authorized Users for the purpose of the
                            Customer using the Services or facilitating the Customer’s use of the Services.{" "}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            <span className={classes.infoBold}>Data Protection Legislation</span> the Irish Data
                            Protection Acts 1988 to 2018, GDPR and any other applicable law or regulation relating to
                            the processing of personal data and to privacy (including the E-Privacy Directive and the
                            European Communities (Electronic Communications Networks and Services) (Privacy and
                            Electronic Communications) Regulations 2011 (“E-Privacy Regulations”), as such legislation
                            shall be supplemented, amended, revised or replaced from time to time
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            <span className={classes.infoBold}>Documentation</span> the instructions for use of the
                            Services made available by the Supplier online at such web address notified by the Supplier
                            from time to time.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            <span className={classes.infoBold}>Effective Date</span> the date upon which assent to this
                            Agreement is indicated by Customer.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            <span className={classes.infoBold}>End-Customers</span> entities who use the Services.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            <span className={classes.infoBold}>
                                Intellectual Property Rights” and "Intellectual Property
                            </span>{" "}
                            all intellectual property rights including patents, trademarks, service marks, design
                            rights, copyright, database rights, moral rights, inventions, know how, confidential
                            information and trade secrets (whether or not any of these are registered and including any
                            applications for registration of any such rights), together with all goodwill attaching or
                            relating thereto, and all rights or forms of protection of a similar nature or having
                            similar effect to any of these which may exist or come into existence in the future anywhere
                            in the world.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            <span className={classes.infoBold}>Normal Business Hours</span> 8 am to 5.30 pm local Oregon
                            time, each Business Day.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            <span className={classes.infoBold}>Nulia Trademarks</span> all names, marks, logos, designs,
                            trade dress and other brand designations used by the Supplier in connection with the
                            Services.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            <span className={classes.infoBold}>Processor</span> has the meaning given to such term in
                            GDPR.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            <span className={classes.infoBold}>Processing</span> has the meaning given to such term in
                            Data Protection Legislation, and Processed and Process shall be interpreted accordingly.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            <span className={classes.infoBold}>Personal Data</span> has the meaning set out in GDPR.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            <span className={classes.infoBold}>Services</span> the services provided by the Supplier to
                            the Customer for use as an End-Customer under this Agreement, which will allow access to the
                            Software and the Documentation via a website-based application (or any other website
                            notified to the Customer by the Supplier from time to time), as are more particularly
                            described in Part 1 of Schedule 1.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            <span className={classes.infoBold}>Software</span> the software provided by the Supplier as
                            part of the Services, details of which are set out in Schedule 2.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            <span className={classes.infoBold}>Support Services</span> the support to be provided by the
                            Supplier in relation to the Services as set out in Part 3 of Schedule 1.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            <span className={classes.infoBold}>Terms of Use</span> the Nulia platform/website Terms of
                            Use to which an End-Customer must agree during configuration and/or continued use of the
                            Services. The Terms of Use will be provided at the time of End-Customer provisioning, and
                            may change from time to time as described in the Terms of Use. The particular terms of the
                            Terms of Use do not constitute part of the Agreement.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            <span className={classes.infoBold}>Trial Period</span> the term of this Agreement, being a
                            period starting on the Effective Date and ending forty-five (45) days thereafter.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            <span className={classes.infoBold}>User Subscriptions</span> the user subscriptions provided
                            to the Customer pursuant to Clause 8.1 which entitle Authorized Users to access and use the
                            Services and the Documentation during the Trial Period in accordance with this Agreement and
                            the Terms of Use.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            <span className={classes.infoBold}>Virus</span> anything or device (including any software,
                            code, file or program) which may: prevent, impair or otherwise adversely affect the
                            operation of any computer software, hardware or network, any telecommunications service,
                            equipment or network or any other service or device; prevent, impair or otherwise adversely
                            affect access to or the operation of any program or data, including the reliability of any
                            program or data (whether by re-arranging, altering or erasing the program or data in whole
                            or part or otherwise); or adversely affect the user experience, including worms, trojan
                            horses, viruses and other similar things or devices.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            1.2 Clause, Schedule and paragraph headings shall not affect the interpretation of this
                            Agreement.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            1.3 A person includes an individual, corporate or unincorporated body (whether or not having
                            separate legal personality), and that person’s legal and personal representatives,
                            successors or permitted assigns.{" "}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            1.4 A reference to a company shall include any company, corporation or other body corporate,
                            wherever and however incorporated or established.{" "}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            1.5 Unless the context otherwise requires, words in the singular shall include the plural
                            and, in the plural, shall include the singular.{" "}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            1.6 Unless the context otherwise requires, a reference to one gender shall include a
                            reference to the other genders.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            1.7 A reference to a statute or statutory provision is a reference to it as it is in force
                            as of the Effective Date.{" "}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            1.8 A reference to a statute or statutory provision shall include all subordinate
                            legislation made as of the Effective Date under that statute or statutory provision.{" "}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            1.9 A reference to writing or written includes e-mail.{" "}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            1.10 References to Clauses, Schedules, and Exhibits are to the Clauses, Schedules, and
                            Exhibits of this Agreement; references to paragraphs are to paragraphs of the relevant
                            Schedule to this Agreement.{" "}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            2 <span className={classes.infoBold}>LICENCE AND USER SUBSCRIPTIONS </span>
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            2.1 This Agreement grants the Customer a non-exclusive, non-transferable, right to use the
                            Services for the duration of the Trial Period.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            2.2 The Customer undertakes that:
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            2.2.1 the maximum number of Authorized Users that it authorizes to access and use the
                            Services shall not exceed the number of User Subscriptions listed in Schedule 1;
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            2.2.2 it will not allow or suffer any User Subscription to be used by more than one
                            individual Authorized User unless it has been reassigned in its entirety to another
                            individual Authorized User, in which case the prior Authorized User shall no longer have any
                            right to access or use the Services;
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            2.2.3 Customer will be required to accept a Terms of Use agreement during the provisioning
                            of the Services in order to use the Services. The Terms of Use may change from time to time
                            following notice as specified in the Terms of Use.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            2.3 The Customer shall not, and it shall procure that the Authorized Users shall not,
                            access, store, distribute or transmit any Viruses, or any material during the course of
                            its/their use of the Services that:
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            2.3.1 is unlawful, harmful, threatening, defamatory, obscene, infringing, harassing or
                            racially or ethnically offensive;
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            2.3.2 facilitates illegal activity;
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            2.3.3 depicts sexually explicit images;
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            2.3.4 promotes unlawful violence;
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            2.3.5 is discriminatory based on race, gender, color, religious belief, sexual orientation,
                            disability; or
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            2.3.6 in a manner that is otherwise illegal or causes damage or injury to any person or
                            property;
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            and the Supplier reserves the right, without liability or prejudice to its other rights to
                            the Customer, to disable the Customer’s access to any material that breaches the provisions
                            of this Clause.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            2.4 The Customer shall not, and it shall procure that the Authorized Users shall not:
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            2.4.1 except as may be allowed by any applicable law which is incapable of exclusion by
                            agreement between the Parties:
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            (A) and except to the extent expressly permitted under this Agreement, attempt to copy,
                            modify, duplicate, create derivative works from, frame, mirror, republish, download,
                            display, transmit, or distribute all or any portion of the Services and/or Software [and/or
                            Documentation (as applicable)] in any form or media or by any means; or
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            (B) attempt to reverse compile, disassemble, reverse engineer or otherwise reduce to
                            human-perceivable form all or any part of the Software; or
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            2.4.2 access all or any part of the Services and/or Software and/or Documentation in order
                            to build a product or service which competes with the Services or Software and/or
                            Documentation; or
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            2.4.3 use the Services and/or Software and/or Documentation to provide services to third
                            parties; or
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            2.4.4 subject to Clause 2.2.1, license, sell, rent, lease, transfer, assign, distribute,
                            display, disclose, or otherwise commercially exploit, or otherwise make the Services and/or
                            Software and/or Documentation available to any third party except the End-Customer and
                            Authorized Users, or
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            2.4.5 attempt to obtain, or assist third parties in obtaining, access to the Services and/or
                            Software and/or Documentation, other than as provided under this Clause 2; and
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            2.5 The Customer shall use all reasonable endeavors to prevent any unauthorized access to,
                            or use of, the Services and, in the event of any such unauthorized access or use, promptly
                            notify the Supplier.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            2.6 The Supplier reserves the right to advertise, promote, market and sell the Services
                            directly and to appoint third-party resellers of the Services, in each case, within any
                            geographic area.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            2.7 The rights provided under this Clause 2 are granted to the Customer only and shall not
                            be considered granted to any Affiliate of the Customer.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            3 <span className={classes.infoBold}>SERVICES</span>
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            3.1 The Supplier shall, during the Trial Period, provide the Services to the Customer on and
                            subject to the terms of this Agreement.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            4 <span className={classes.infoBold}>CUSTOMER DATA</span>
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            4.1 The Customer and/or its Authorized Users, as applicable, shall own all right, title and
                            interest in and to all of the Customer Data and shall have sole responsibility for the
                            legality, reliability, integrity, accuracy and quality of the Customer Data.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            4.2 Retention of any Personal Data contained in Customer Data is dealt with in the
                            Supplier's Privacy & Cookies Policy available at https://nulia.cloud/privacy. In the event
                            of any loss or damage to Customer Data, the Customer’s sole and exclusive remedy shall be
                            for the Supplier to use reasonable commercial endeavors to restore the lost or damaged
                            Customer Data.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            4.3 To the extent that the Supplier (as Processor) Processes Personal Data pursuant to this
                            Agreement on behalf of the Customer in respect of which Data Protection Legislation applies,
                            the provisions set out in Schedule 4 shall apply to such Processing. If there is an
                            inconsistency between any of the provisions or definitions of this Agreement and the
                            provisions of Schedule 4, the provisions of Schedule 4 shall prevail in respect of such
                            conflict.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            5 <span className={classes.infoBold}>THIRD PARTY DATA </span>
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            5.1 The Supplier does not own any data, information or material that the Customer or any
                            Authorized User submits to the Services in the course of using the Services, including any
                            Customer Data ("Received Data"). The Customer or the Authorized Users, not the Supplier,
                            shall have sole responsibility for the accuracy, quality, integrity, legality, reliability,
                            appropriateness, and Intellectual Property ownership or right to use of all Received Data,
                            and the Supplier shall not be responsible or liable for any infringement of Intellectual
                            Property Rights of any third party or for the deletion, correction, destruction, damage,
                            loss or failure to store any Received Data. The Supplier reserves the right to withhold,
                            remove and/or discard Received Data without notice for any breach. Upon termination for
                            cause or the expiration of the Trial Period, the Customer’s right to access or use Received
                            Data immediately ceases, and the Supplier shall have no obligation to maintain or forward
                            any Received Data. Supplier also reserves the right to withhold, remove and/or discard
                            Received Data upon expiration of the Trial Period.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            6 <span className={classes.infoBold}>SUPPLIER’S OBLIGATIONS</span>
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            6.1 The Supplier undertakes that the Services described in Schedule 1 will be provided with
                            reasonable skill and care.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            6.2 The undertaking at Clause 6.1 shall not apply to the extent of any non-conformance which
                            is caused by use of the Services contrary to the Supplier’s instructions, or modification or
                            alteration of the Services by any party other than the Supplier or the Supplier’s duly
                            authorized contractors or agents. Notwithstanding the foregoing, the Supplier:
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            6.2.1 does not warrant that availability of the Services will be uninterrupted or
                            error-free; or that the Services and/or the information obtained through the Services will
                            be error free or will meet the Customer’s requirements; and
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            6.2.2 is not responsible for any delays, delivery failures, or any other loss or damage
                            resulting from the transfer of data over communications networks and facilities, including
                            the internet, and the Customer acknowledges that the Services may be subject to limitations,
                            delays and other problems inherent in the use of such communications facilities.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            6.3 This Agreement shall not prevent the Supplier from entering into similar (or other)
                            agreements with third parties, or from independently developing, using, selling or licensing
                            documentation, products, software and/or services which are similar to those provided under
                            this Agreement.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            7 <span className={classes.infoBold}>CUSTOMER’S OBLIGATIONS</span>
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            The Customer shall:
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            7.1 provide the Supplier with:
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            7.1.1 all necessary co-operation in relation to this Agreement; and
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            7.1.2 all necessary access to such information as may be required by the Supplier;
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            in order to provide the Services, including but not limited to Customer Data, security
                            access information and configuration services;
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            7.2 comply with all applicable laws and regulations with respect to its activities under
                            this Agreement;
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            7.3 carry out all other Customer responsibilities set out in this Agreement in a timely and
                            efficient manner.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            7.4 ensure that the Authorized Users use the Services in accordance with the terms and
                            conditions of this Agreement;
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            7.5 obtain and shall maintain all necessary licenses, consents, and permissions necessary
                            for the Customer, its contractors and agents to perform their obligations under this
                            Agreement;
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            7.6 ensure that its network and systems comply with the relevant specifications provided by
                            the Supplier from time to time; and
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            7.7 be solely responsible for (i) procuring and maintaining their network connections and
                            telecommunications links from their systems to the Supplier’s data centers, and (ii) all
                            problems, conditions, delays, delivery failures and all other loss or damage arising from or
                            relating to the such network connections or telecommunications links or caused by the
                            internet.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            7.8 The Customer warrants that Customer has not previously entered into this Agreement with
                            Supplier, and warrants that the Trial Period is the first Trial Period in which Customer has
                            participated with Supplier.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            8 <span className={classes.infoBold}>CHARGES AND PAYMENT</span>
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            8.1 Supplier acknowledges that, for the Trial Period, Customer shall be provided its rights
                            under this agreement free of charge.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            8.2 Customer acknowledges that, in any subsequent agreement between Supplier and Customer
                            after the Trial Period, Supplier will require fees to be paid to obtain the rights of the
                            Customer otherwise provided under this Agreement.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            8.3
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            9 <span className={classes.infoBold}>PROPRIETARY RIGHTS</span>
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            9.1 The Customer acknowledges and agrees that the Supplier and/or its licensors own all
                            intellectual property rights in the Services and the Software and the Documentation. Except
                            as expressly stated herein, this Agreement does not grant the Customer or Authorized Users
                            any Intellectual Property Rights, or any other rights or licenses in respect of the Services
                            or the Software or the Documentation.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            9.2 The Supplier confirms that it has all the rights in relation to the Services and the
                            Software and the Documentation that are necessary to grant all the rights it purports to
                            grant under, and in accordance with, the terms of this Agreement.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            10 <span className={classes.infoBold}>Confidentiality</span>
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            10.1 Each Party may be given access to Confidential Information from the other Party in
                            order to perform its obligations under this Agreement. A Party’s Confidential Information
                            shall not be deemed to include information that:
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            10.1.1 is or becomes publicly known other than through any act or omission of the receiving
                            Party;
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            10.1.2 was in the other Party’s lawful possession before the disclosure;
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            10.1.3 is lawfully disclosed to the receiving Party by a third party without restriction on
                            disclosure;
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            10.1.4 is independently developed by the receiving Party, which independent development can
                            be shown by written evidence; or
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            10.1.5 is required to be disclosed by law, by any court of competent jurisdiction or by any
                            regulatory or administrative body.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            10.2 Each Party shall hold the other’s Confidential Information in confidence and, unless
                            required by law, not make the other’s Confidential Information available to any third party,
                            or use the other’s Confidential Information for any purpose other than the implementation of
                            this Agreement.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            10.3 Each Party shall take all reasonable steps to ensure that the other’s Confidential
                            Information to which it has access is not disclosed or distributed by its employees or
                            agents in violation of the terms of this Agreement.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            10.4 Neither Party shall be responsible for any loss, destruction, alteration or disclosure
                            of Confidential Information caused by any third party.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            10.5 The Customer acknowledges that details of the Services, and the results of any
                            performance tests of the Services, are included within the Supplier’s Confidential
                            Information.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            10.6 The Supplier acknowledges that the Customer Data is the Confidential Information of the
                            Customer.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            10.7 This Clause 10 shall survive termination of this Agreement, however arising.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            10.8 No Party shall make, or permit any person to make, any public announcement concerning
                            this Agreement without the prior written consent of the other Party (such consent not to be
                            unreasonably withheld or delayed), except as required by law, any governmental or regulatory
                            authority (including, without limitation, any relevant securities exchange), any court or
                            other authority of competent jurisdiction.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            11 <span className={classes.infoBold}>Indemnity</span>
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            11.1 Customer shall indemnify, defend and hold harmless Supplier from and against any claims
                            by third parties and expenses (including legal fees and court costs) arising from: (i)
                            damage to tangible property, personal injury or death caused by Customer's gross negligence,
                            willful misconduct, criminal or fraudulent acts; (ii) any misuse of Supplier's Intellectual
                            Property or Confidential Information which is not expressly permitted by this Agreement; and
                            (iii) any breach of Data Protection Legislation.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            11.2 Customer shall also indemnify, defend and hold Supplier harmless from any and all
                            claims by third parties and expenses (including legal fees and court costs) arising out of
                            or in connection with, in respect of the Customer, the use of the Services other than as
                            permitted by this Agreement and the Terms of Use.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            11.3 The foregoing indemnities are subject to the following:
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            11.3.1 on the Supplier becoming aware of any such claim, the Customer is given prompt notice
                            of the same;
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            11.3.2 the Supplier provides reasonable co-operation to the Customer in the defense and
                            settlement of such claim, at the Customer's expense; and
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            11.3.3 the Customer is given sole authority to defend or settle the claim.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            12 <span className={classes.infoBold}>LIMITATION OF LIABILITY</span>
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            12.1 This Clause 12 sets out the entire financial liability of the Supplier (including any
                            liability for the acts or omissions of its employees, agents and sub-contractors) to the
                            Customer and Authorized Users:
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            12.1.1 arising under or in connection with this Agreement;
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            12.1.2 in respect of any use made by the Customer or Authorized Users of the Services or any
                            part of them; and
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            12.1.3 in respect of any representation, statement or tortious act or omission (including
                            negligence) arising under or in connection with this Agreement.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            12.2 Except as expressly and specifically provided in this Agreement:
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            12.2.1 the Customer assumes sole responsibility for results obtained from the use of the
                            Services by the Customer or Authorized Users, and for conclusions drawn from such use. The
                            Supplier shall have no liability for any damage caused by errors or omissions in any
                            information, instructions or scripts provided to the Supplier by the Customer or Authorized
                            Users in connection with the Services, or any actions taken by the Supplier at the direction
                            of the Customer or Authorized Users; and
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            12.3 Nothing in this Agreement excludes the liability of the Supplier:
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            12.3.1 for death or personal injury caused by the Supplier’s negligence; or
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            12.3.2 for fraud or fraudulent misrepresentation.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            12.4 Subject to Clause 12.2 and Clause 12.3, the Supplier shall not be liable whether in
                            tort (including for negligence or breach of statutory duty), contract, misrepresentation,
                            restitution or otherwise for any loss of profits, loss of business, depletion of goodwill
                            and/or similar losses or loss or corruption of data or information, or pure economic loss,
                            or for any special, indirect or consequential loss, costs, damages, charges or expenses
                            however arising under this Agreement; and
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            13 <span className={classes.infoBold}>DISCLAIMER</span>
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            13.1 THE SERVICES, SOFTWARE, AND DOCUMENTATION ARE PROVIDED "AS IS."  SUPPLIER MAKES NO
                            REPRESENTATIONS OR WARRANTIES OF ANY KIND, WHETHER EXPRESS, IMPLIED, STATUTORY, IMPLIED BY
                            STATUTE OR COMMON LAW, OR OTHERWISE WITH RESPECT TO THE SERVICES, SOFTWARE, AND
                            DOCUMENTATION, AND SUCH REPRESENTATIONS AND WARRANTIES ARE EXCLUDED FROM THIS AGREEMENT.
                            EXCEPT TO THE EXTENT PROHIBITED BY APPLICABLE LAW, SUPPLIER DISCLAIMS ALL WARRANTIES,
                            INCLUDING, WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, SATISFACTORY
                            QUALITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, AND ANY WARRANTIES ARISING OUT
                            OF ANY COURSE OF DEALING OR USAGE OF TRADE. SUPPLIER DOES NOT WARRANT THAT THE SERVICES,
                            SOFTWARE, AND DOCUMENTATION WILL FUNCTION AS DESCRIBED, WILL BE UNINTERRUPTED OR ERROR FREE,
                            OR FREE OF HARMFUL COMPONENTS, OR THAT THE DATA CUSTOMERS OR AUTHORIZED USERS STORE WITHIN
                            THE SERVICE WILL BE SECURE OR NOT OTHERWISE LOST OR DAMAGED. SUPPLIER SHALL NOT BE
                            RESPONSIBLE FOR ANY SERVICE INTERRUPTIONS, INCLUDING, WITHOUT LIMITATION, POWER OUTAGES,
                            SYSTEM FAILURES OR OTHER INTERRUPTIONS, INCLUDING THOSE THAT AFFECT THE RECEIPT, PROCESSING,
                            ACCEPTANCE, COMPLETION, OR SETTLEMENT OF ANY PAYMENT SERVICES. NO ADVICE OR INFORMATION
                            OBTAINED BY CUSTOMER OR AUTHORIZED USERS FROM SUPPLIER OR FROM ANY THIRD PARTY OR THROUGH
                            THE SERVICES, SOFTWARE, OR DOCUMENTATION SHALL CREATE ANY WARRANTY NOT EXPRESSLY STATED IN
                            THIS AGREEMENT.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            14 <span className={classes.infoBold}>NON-SOLICITATION</span>
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            14.1 The Customer hereby covenants to the Supplier that it shall not, and it shall procure
                            that its Affiliates shall not, during the period of three (3) years from the Effective Date,
                            solicit the services of, or endeavor to entice away from the Supplier any director, employee
                            or consultant of the Supplier who at the Effective Date or at any time during the period of
                            twelve (12) months prior to the Effective Date was a director, employee or consultant of the
                            Supplier (whether or not such person would commit any breach of his contract of employment
                            or engagement by reason of leaving the service of the Supplier).
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            15 <span className={classes.infoBold}>TERM AND TERMINATION</span>
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            15.1 This Agreement shall, unless otherwise terminated as provided in this Clause 15,
                            commence on the Effective Date and shall continue for the Trial Period, unless
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            15.1.1 otherwise terminated in accordance with the provisions of this Agreement.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            15.2 On termination of this Agreement for any reason (including the end of the Trial
                            Period):
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            15.2.1 all licenses granted and User Subscriptions under this Agreement shall immediately
                            terminate;
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            15.2.2 each Party shall return and make no further use of any equipment, property,
                            documentation and other items (and all copies of them) belonging to the other Party; and
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            15.2.3 the Supplier may destroy or otherwise dispose of any of the Customer Data in its
                            possession.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            16 <span className={classes.infoBold}>COMPLIANCE WITH LAWS</span>
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            Both Parties shall comply with all applicable laws, rules and regulations in the performance
                            of its obligations under this Agreement.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            17 <span className={classes.infoBold}>INSURANCE</span>
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            The Customer shall maintain, at its own expense, all necessary insurance against all losses,
                            claims, demands, proceedings, damages, costs, and expenses arising out of or in connection
                            with this Agreement or use of the Services.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            18 <span className={classes.infoBold}>FORCE MAJEURE</span>
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            The Supplier shall have no liability to the Customer under this Agreement if it is prevented
                            from or delayed in performing its obligations under this Agreement, or from carrying on its
                            business, by acts, events, omissions or accidents beyond its reasonable control, including,
                            without limitation, strikes, lock-outs or other industrial disputes (whether involving the
                            workforce of the Supplier or any other party), failure of a utility service or transport or
                            telecommunications network, act of God, war, riot, civil commotion, malicious damage,
                            compliance with any law or governmental order, rule, regulation or direction, accident,
                            breakdown of plant or machinery, fire, flood, storm or default of suppliers or
                            sub-contractors, provided that the Customer is notified of such an event and its expected
                            duration.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            19 <span className={classes.infoBold}>Conflict</span>
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            Subject to Clause 4.3, if there is an inconsistency between any of the provisions in the
                            main body of this Agreement and the Schedules, the provisions in the main body of this
                            Agreement shall prevail.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            20 <span className={classes.infoBold}>Variation</span>
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            No variation of this Agreement shall be effective unless it is in writing and signed by the
                            Parties (or their authorized representatives).
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            21 <span className={classes.infoBold}>Waiver</span>
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            No failure or delay by a Party to exercise any right or remedy provided under this Agreement
                            or by law shall constitute a waiver of that or any other right or remedy, nor shall it
                            prevent or restrict the further exercise of that or any other right or remedy. No single or
                            partial exercise of such right or remedy shall prevent or restrict the further exercise of
                            that or any other right or remedy.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            22 <span className={classes.infoBold}>RIGHTS AND REMEDIES</span>
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            Except as expressly provided in this Agreement, the rights and remedies provided under this
                            Agreement are in addition to, and not exclusive of, any rights or remedies provided by law.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            23 <span className={classes.infoBold}>Severance</span>
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            23.1 If any provision (or part of a provision) of this Agreement is found by any court or
                            administrative body of competent jurisdiction to be invalid, unenforceable or illegal, the
                            other provisions shall remain in force.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            23.2 If any invalid, unenforceable or illegal provision would be valid, enforceable or legal
                            if some part of it were deleted, the provision shall apply with whatever modification is
                            necessary to give effect to the commercial intention of the Parties.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            24 <span className={classes.infoBold}>Entire Agreement</span>
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            24.1 This Agreement, and any documents referred to in it, constitute the whole agreement
                            between the Parties and supersede any previous arrangement, understanding or agreement
                            between them relating to the subject matter they cover.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            24.2 Each of the Parties acknowledges and agrees that in entering into this Agreement it
                            does not rely on any undertaking, promise, assurance, statement, representation, warranty or
                            understanding (whether in writing or not) of any person (whether party to this Agreement or
                            not) relating to the subject matter of this Agreement, other than as expressly set out in
                            this Agreement.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            25 <span className={classes.infoBold}>Assignment</span>
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            25.1 The Customer shall not assign, transfer, charge, sub-contract or deal in any other
                            manner with all or any of its rights or obligations under this Agreement.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            25.2 The Supplier may at any time assign, transfer, charge, sub-contract or deal in any
                            other manner with all or any of its rights or obligations under this Agreement.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            26 <span className={classes.infoBold}>NO PARTNERSHIP OR AGENCY </span>
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            Nothing in this Agreement is intended to or shall operate to create a partnership between
                            the Parties, or authorize either Party to act as agent for the other, and neither Party
                            shall have the authority to act in the name or on behalf of or otherwise to bind the other
                            in any way (including, but not limited to, the making of any representation or warranty, the
                            assumption of any obligation or liability and the exercise of any right or power).
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            27 <span className={classes.infoBold}>Notices</span>
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            27.1 Any notice required to be given under this Agreement shall be in writing and shall be
                            delivered by hand or sent by pre-paid first-class post or recorded delivery post to the
                            other Party at its address set out in this Agreement, or such other address as may have been
                            notified by that Party for such purposes, or sent by email to the other Party’s email
                            address as set out in this Agreement.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            27.2 A notice delivered by hand shall be deemed to have been received when delivered (or if
                            delivery is not in business hours, at 9 am on the first Business Day following delivery). A
                            correctly addressed notice sent by pre-paid first-class post or recorded delivery post shall
                            be deemed to have been received at the time at which it would have been delivered in the
                            normal course of post. A notice sent by email shall be deemed to have been received at the
                            time of transmission.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            27.3 The notice details for the Parties are as follows:
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            The Supplier:
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            Name: Michele Zimba
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            Address: 180 West 8th Avenue, Suite 200, Eugene, OR 97401
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            Email: michele@nulia.cloud
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            The Customer will provide an email address for receiving notice during configuration of the
                            Services.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            28 <span className={classes.infoBold}>EXECUTION</span>
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            28.1 This Agreement may be executed by electronic affirmation on the part of the Customer,
                            including but not limited to by clicking a button, checkbox, radio button, or other
                            electronic interface to indicate consent.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            28.2 The Parties each warrant to one another that they are respectively duly authorized to
                            enter into this Agreement, and to have the Agreement executed as set out below or by
                            electronic affirmation as permitted under clause 28.1 above and that, upon such execution,
                            the Agreement shall have full force and effect on both Parties.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            29 <span className={classes.infoBold}>GOVERNING LAW</span>
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            This Agreement and any dispute or claim arising out of or in connection with it or its
                            subject matter or formation (including non-contractual disputes or claims) shall be governed
                            by and construed in accordance with the law of the State of Oregon and the federal laws of
                            the United States.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            30 <span className={classes.infoBold}>Jurisdiction</span>
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            Each Party irrevocably agrees that the courts within the State of Oregon shall have
                            exclusive jurisdiction to settle any dispute or claim arising out of or in connection with
                            this Agreement or its subject matter or formation (including non-contractual disputes or
                            claims), and consents to such jurisdiction. Each Party consents to venue in the courts
                            sitting in the State of Oregon. Customer waives all defenses of lack of personal
                            jurisdiction and forum non convenience.
                        </Typography>
                    </Grid>
                </Grid>
            </section>
            <section>
                <Typography
                    variant="h6"
                    align="center"
                    color="textSecondary"
                    className={classes.bodyHeader}
                    gutterBottom={true}
                    paragraph={true}
                >
                    SCHEDULE 1 – PRODUCT AND SERVICES
                </Typography>
                <Typography
                    variant="h6"
                    align="center"
                    color="textSecondary"
                    className={classes.bodyHeader}
                    gutterBottom={true}
                    paragraph={true}
                >
                    Part 1
                </Typography>

                <Grid container={true} direction="column" className={classes.gridSection} spacing={1}>
                    <Grid item={true} className={classes.formBox}>
                        <Typography variant="subtitle1" color="textSecondary" paragraph={true}>
                            1. THE PRODUCT
                        </Typography>
                    </Grid>
                    <Grid item={true} className={classes.formBox}>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            The Nulia Platform is a web-based adoption and change management platform that uses machine
                            learning and artificial intelligence to help users deeply use digital technologies to
                            improve business processes and increase business performance. Starting with Microsoft's
                            Microsoft 365 solution, the platform combines a personalized approach with continuous
                            measurement that verifiably ensures each user reaches their desired digital transformation
                            outcomes. Nulia unlocks the full value of Microsoft 365 for users while enabling businesses
                            to maximize return from their Microsoft 365 investment.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            Different versions of the Nulia Platform will be available:
                        </Typography>
                        <ul>
                            <li>
                                <Typography variant="body2" color="textSecondary" paragraph={true}>
                                    <span className={classes.bodyHeader}>Complete Version: </span>
                                    The Nulia Complete Version will allow end-users to be provisioned with the full set
                                    of the Nulia Outcome Catalog. All content modalities and features of the Nulia
                                    platform will be available to end-users.
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="body2" color="textSecondary" paragraph={true}>
                                    <span className={classes.bodyHeader}>Trial Version: </span>: The Nulia Trial Version
                                    may include all of the Nulia Full Version functionality, or may only include a
                                    limited subset of functionality, at the discretion of Supplier.
                                </Typography>
                            </li>
                        </ul>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            Authorized Licenses: 5,000 Licenses (trial program)
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            Additional Licenses:
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            No additional licenses will be provided during the Trial Period
                        </Typography>
                    </Grid>
                </Grid>
            </section>
            <section>
                <Typography
                    variant="h6"
                    align="center"
                    color="textSecondary"
                    className={classes.bodyHeader}
                    gutterBottom={true}
                    paragraph={true}
                >
                    Part 2
                </Typography>
                <Grid container={true} direction="column" className={classes.gridSection} spacing={1}>
                    <Grid item={true} className={classes.formBox}>
                        <Typography variant="subtitle1" color="textSecondary" paragraph={true}>
                            SUBSCRIPTION FEES
                        </Typography>
                    </Grid>
                    <Grid item={true} className={classes.formBox}>
                        <ul>
                            <li>
                                <Typography variant="body2" color="textSecondary" paragraph={true}>
                                    Full Version: $0 wholesale per authorized user
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="body2" color="textSecondary" paragraph={true}>
                                    Trial Version (90 day trial period): $0
                                </Typography>
                            </li>
                        </ul>
                    </Grid>
                </Grid>
            </section>
            <section>
                <Typography
                    variant="h6"
                    align="center"
                    color="textSecondary"
                    className={classes.bodyHeader}
                    gutterBottom={true}
                    paragraph={true}
                >
                    Part 3
                </Typography>
                <Grid container={true} direction="column" className={classes.gridSection} spacing={1}>
                    <Grid item={true} className={classes.formBox}>
                        <Typography variant="subtitle1" color="textSecondary" paragraph={true}>
                            SUPPORT SERVICES (Complete Version):
                        </Typography>
                    </Grid>
                    <Grid item={true} className={classes.formBox}>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            Nulia provides the following services and support for partners and end customers
                            admins/named end customer contacts:
                        </Typography>
                        <ul>
                            <li>
                                <Typography variant="body2" color="textSecondary" paragraph={true}>
                                    Nulia strives for a seamless, self-service installation and onboarding experience.
                                    When necessary, onboarding support for partner and end customer admins is provided
                                    to assist with the Nulia platform installation process.
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="body2" color="textSecondary" paragraph={true}>
                                    Post-installation customer service, and licensing support is provided to authorized
                                    partners and select named end customer contacts. At the sole discretion of Nulia,
                                    based on volume; Phone, email, and/or chat channels of contact may be provided to
                                    partners and end customers for this purpose.
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="body2" color="textSecondary" paragraph={true}>
                                    Post-installation technical support is provided to authorized partners and select
                                    named end customer contacts. Direct end customer support may be provided to those
                                    select named customer contacts depending on the size of the customer, the
                                    relationship with partner or reseller, and further considerations that define the
                                    relationship with Nulia.
                                </Typography>
                            </li>
                        </ul>
                    </Grid>
                    <Grid item={true} className={classes.formBox}>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            As a non-mission critical application, Nulia strives to achieve the following service level
                            goals:
                        </Typography>
                        <ul>
                            <li>
                                <Typography variant="body2" color="textSecondary" paragraph={true}>
                                    Customer service, licensing, billing support: Response to inquiries within 48
                                    business hours of submission
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="body2" color="textSecondary" paragraph={true}>
                                    Technical support issues: Response and acknowledgement to reports of technical
                                    issues within 24 business hours
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="body2" color="textSecondary" paragraph={true}>
                                    Service level availability: Nulia looks to exceed 99% uptime with availability of
                                    our web-based solution. The Nulia Platform has dependencies on the end customer MSFT
                                    tenant and network functioning normally, MSFT applications operating normally, and
                                    the availability of MSFT usage information.
                                </Typography>
                            </li>
                        </ul>
                    </Grid>
                    <Grid item={true} className={classes.formBox}>
                        <Typography variant="subtitle1" color="textSecondary" paragraph={true}>
                            SUPPORT SERVICES (Trial Version):
                        </Typography>
                    </Grid>
                    <Grid item={true} className={classes.formBox}>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            Nulia provides onboarding support to assist with the Nulia platform installation process for
                            the Trial Version. Notwithstanding the foregoing, post-installation customer service and
                            technical support is not provided with the Trial Version, and service levels are not
                            specified for the Trial Version.
                        </Typography>
                    </Grid>
                </Grid>
            </section>
            <section>
                <Typography
                    variant="h6"
                    align="center"
                    color="textSecondary"
                    className={classes.bodyHeader}
                    gutterBottom={true}
                    paragraph={true}
                >
                    SCHEDULE 2 – SOFTWARE
                </Typography>
                <Grid container={true} direction="column" className={classes.gridSection} spacing={1}>
                    <Grid item={true} className={classes.formBox}>
                        <Typography variant="subtitle1" color="textSecondary" paragraph={true}>
                            The Nulia Platform includes a selection of end-user focused Outcomes that can be assigned to
                            authorized users. This catalogue of Outcomes will be updated from time to time, and an
                            up-to-date listing of this information is available via the Platform to all authorized users
                            of the Nulia Platform, including Nulia Admins.
                        </Typography>
                    </Grid>
                </Grid>
            </section>
            <section>
                <Typography
                    variant="h6"
                    align="center"
                    color="textSecondary"
                    className={classes.bodyHeader}
                    gutterBottom={true}
                    paragraph={true}
                >
                    SCHEDULE 3 – RESERVED
                </Typography>
            </section>
            <section>
                <Typography
                    variant="h6"
                    align="center"
                    color="textSecondary"
                    className={classes.bodyHeader}
                    gutterBottom={true}
                    paragraph={true}
                >
                    SCHEDULE 4 – DATA PROCESSING TERMS
                </Typography>
                <Grid container={true} direction="column" className={classes.gridSection} spacing={1}>
                    <Grid item={true} className={classes.formBox}>
                        <Typography variant="subtitle1" color="textSecondary" paragraph={true}>
                            “We”, “Us”, “Our”: Nulia AMC, LLC; and
                        </Typography>
                        <Typography variant="subtitle1" color="textSecondary" paragraph={true}>
                            “You”, “Your”: the Customer
                        </Typography>
                        <Typography variant="subtitle1" color="textSecondary" paragraph={true}>
                            We act as a Processor on behalf of You, who act as a Controller, in respect of the
                            Processing referred to in this Schedule.
                        </Typography>
                    </Grid>
                    <Grid item={true} className={classes.formBox}>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            <span className={classes.infoBold}>1. Definitions</span>
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            1.1 The following definitions and rules of interpretation apply in this Schedule. Defined
                            terms used in this Schedule which have not been given definitions shall have the meanings
                            given to such terms in the Agreement.
                        </Typography>
                    </Grid>
                    <Grid item={true} className={classes.formBox}>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            <span className={classes.infoBold}>Appropriate Technical and Organizational Measures </span>
                            has the meaning given to such term in Data Protection Legislation (including, as
                            appropriate, the measures referred to in Article 32(1) of the GDPR).
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            <span className={classes.infoBold}>Authorized Person </span>
                            the personnel authorized on Your behalf to provide instructions to Us in relation to the
                            Processing provisions in this Schedule.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            <span className={classes.infoBold}>Business Purpose </span>
                            the provision of the Services.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            <span className={classes.infoBold}>Controller </span>
                            has the meaning given to such term in GDPR.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            <span className={classes.infoBold}>Data </span>
                            any data or information, in whatever form, including but not limited to images, still and
                            moving, and sound recordings.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            <span className={classes.infoBold}>Data Protection Legislation </span>
                            means the Irish Data Protection Acts 1988 to 2018, GDPR and any other applicable law or
                            regulation relating to the processing of personal data and to privacy (including the
                            E-Privacy Directive and the European Communities (Electronic Communications Networks and
                            Services) (Privacy and Electronic Communications) Regulations 2011 (“E-Privacy
                            Regulations”), as such legislation shall be supplemented, amended, revised or replaced from
                            time to time.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            <span className={classes.infoBold}>Data Protection Officer </span>a data protection officer
                            appointed pursuant to Data Protection Legislation.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            <span className={classes.infoBold}>Data Subject </span>
                            an individual who is the subject of Personal Data for the purposes of this Schedule, in
                            respect of which the Customer is the Controller, including Authorized Users.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            <span className={classes.infoBold}>Delete </span>
                            to remove or obliterate Personal Data such that it cannot be recovered or reconstructed.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            <span className={classes.infoBold}>DPC </span>
                            Data Protection Commission, Canal House, Station Road, Portarlington, Co. Laois, R32 AP23,
                            Ireland.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            <span className={classes.infoBold}>EEA </span>
                            European Economic Area.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            <span className={classes.infoBold}>Further Sub-processors </span>
                            has the meaning given to such term in Clause 11.1 of this Schedule.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            <span className={classes.infoBold}>GDPR </span>
                            General Data Protection Regulation (EU) 2016/679.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            <span className={classes.infoBold}>Our System </span>
                            any information technology system or systems owned or operated by Us to which Your Data is
                            delivered or on which the Services are performed.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            <span className={classes.infoBold}>Personal Data </span>
                            has the meaning set out in GDPR, and for the purposes of this Schedule, relates only to
                            personal data, or any part of such personal data, in respect of which You are the
                            Controller, and in respect of which We are a Processor.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            <span className={classes.infoBold}>Personal Data Breach </span>
                            means any “personal data breach” as defined in the GDPR in respect of the Personal Data
                            which is caused by Us.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            <span className={classes.infoBold}>Processor </span>
                            has the meaning given to such term in GDPR.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            <span className={classes.infoBold}>Processing </span>
                            has the meaning given to such term in Data Protection Legislation, and Processed and Process
                            shall be interpreted accordingly.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            <span className={classes.infoBold}>Representatives </span>a Party’s employees, officers,
                            representatives, advisers or subcontractors involved in the provision or receipt of the
                            Services.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            <span className={classes.infoBold}>Restricted Transfer </span>
                            any transfer of Personal Data to countries outside of the EEA which are not subject to an
                            adequacy decision by the European Commission, where such transfer would be prohibited by
                            Data Protection Legislation.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            <span className={classes.infoBold}>Security Features </span>
                            any security feature, including any encryption, pseudonymisation, key, PIN, password, token
                            or smartcard.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            <span className={classes.infoBold}>Specific Instructions </span>
                            instructions meeting the criteria set out in Clause 2.1 of this Schedule.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            <span className={classes.infoBold}>Standard Contractual Clauses </span>
                            the contractual clauses dealing with the transfer of Personal Data outside the EEA, which
                            have been approved by (i) the European Commission under Data Protection Legislation, or (ii)
                            by the DPC or an equivalent competent authority under Data Protection Legislation.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            <span className={classes.infoBold}>Sub-processor </span>
                            means any natural or legal person, public authority, agency or other body who Processes
                            Personal Data on behalf of a Processsor.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            <span className={classes.infoBold}>Your Data </span>
                            the Personal Data of Data Subjects which We have access to in respect of the provision of
                            the Services, and any other Personal Data Processed by Us on behalf of You.
                        </Typography>
                    </Grid>
                    <Grid item={true} className={classes.formBox}>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            2. <span className={classes.infoBold}>Services</span>
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            2.1 We shall not act on any specific instructions given by You from time to time during the
                            Trial Period in respect of Processing unless they are:
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            2.1.1 in writing (including by electronic means); and
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            2.1.2 given by an Authorized Person.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            2.3 We shall Process Your Data for the Business Purpose only and in compliance with Your
                            instructions from time to time, which may be:
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            2.3.1 Specific Instructions; or
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            2.3.2 the general instructions set out in this Schedule or the Agreement unless required to
                            do otherwise by law, in which case, where legally permitted, We shall inform You of such
                            legal requirement before Processing.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            2.4 The types of Personal Data to be Processed pursuant to this Schedule shall include (but
                            shall not be limited to) Data Subject’s names, email addresses, phone numbers, and the
                            categories of Data Subject to whom such Personal Data relates shall be Authorized Users’ and
                            other employees, individual customers, individual suppliers, agents etc. of the Customer.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            3. <span className={classes.infoBold}>Parties' obligations</span>
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            3.1 We shall:
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            3.1.1 only make copies of Your Data to the extent reasonably necessary for the Business
                            Purpose (which, for clarity, may include for generating logs in relation to the use of the
                            Services, back-up, mirroring (and similar availability enhancement techniques), security,
                            disaster recovery and testing the Services); and
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            3.1.2 not extract, reverse-engineer, re-utilise, use, exploit, redistribute, re-disseminate,
                            copy or store Your Data other than for the Business Purpose.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            3.2 We shall notify You in writing without delay of any situation or envisaged development
                            that shall in any way change the ability of Us to Process Your Data as set out in this
                            Schedule.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            3.4 At Your request and cost, We shall provide to You with a copy of all Your Data held by
                            Us in a commonly used format. We shall comply with any request from You requiring Us to
                            Delete any of Your Data.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            3.5 At Your request and cost, taking into account the nature of Our Processing of the
                            Personal Data and the information available, We shall provide to You such information and
                            such assistance as You may reasonably require, and within the timescales reasonably
                            specified by You, to allow You to comply with Your obligations under Data Protection
                            Legislation,
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            3.6 Any proposal by Us to in any way use or make available Your Data other than as provided
                            for pursuant to this Schedule shall be subject to prior written approval of You.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            3.7 You acknowledge that We are under no duty to investigate the completeness, accuracy or
                            sufficiency of (i) any instructions received from You, or (ii) any of Your Data.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            3.8 You shall:
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            3.8.1 ensure that You are entitled to transfer Your Data to Us so that We may lawfully
                            process and transfer (if applicable) Your Data in accordance with this Schedule;
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            3.8.3 notify Us in writing without delay of any situation or envisaged development that
                            shall in any way influence, change or limit the ability of Us to Process Your Data as set
                            out in this Agreement;
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            3.8.5 provide such information and such assistance to Us as We may reasonably require, and
                            within the timescales reasonably specified by Us, to allow Us to comply with Our obligations
                            under Data Protection Legislation; and
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            3.8.6 ensure that You fully comply with Your obligations pursuant to Data Protection
                            Legislation.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            4. <span className={classes.infoBold}>Our employees</span>
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            4.1 We shall take reasonable steps to ensure that Our employees have committed themselves to
                            a binding duty of confidentiality in respect of Your Data.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            5. <span className={classes.infoBold}>Records</span>
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            5.1 We shall keep at Our normal place of business records (including in electronic form)
                            relating to all categories of Processing activities carried out on behalf of You,
                            containing:
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            5.1.1 the general description of the security measures taken in respect of the Personal
                            Data, including details of any Security Features and the Appropriate Technical and
                            Organizational Measures;
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            5.1.2 the name and contact details of Us; any Sub-processor; and where applicable Our
                            representatives; and where applicable any Data Protection Officer appointed by Us;
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            5.1.3 the categories of Processing by Us on behalf of You; and
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            5.1.4 details of any non-EEA Personal Data transfers, and the safeguards in place in respect
                            of such transfers.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            6. <span className={classes.infoBold}>Audits</span>
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            6.1 Subject to Clause 6.2, 6.3 and 6.5, and to the extent required by Data Protection
                            Legislation, You shall have the right to examine and review the use by Us of Your Data only
                            for the purpose of ascertaining that Your Data has been used and Processed in accordance
                            with the terms of this Schedule.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            6.2 An audit under this Clause 6 shall be carried out on the following basis: (i) You must
                            first contact Us in writing asking for evidence of compliance with Our obligations under
                            this Schedule, and We shall respond to such email within thirty (30) Business Days; (ii) if
                            We have not responded to Your email with a response which is reasonably satisfactory to You
                            within such thirty (30) Business Day period then, no more than once in any twelve (12) month
                            period and during Normal Business Hours during the course of one Business Day You may audit
                            Our Processing of Your Personal Data at a location agreed by Us. You shall bear the
                            reasonable expenses incurred by Us in respect of any such audit and any such audit shall not
                            interfere with the normal and efficient operation of Our business. We may require, as a
                            condition of granting such access, that You (and representatives of You) enter into
                            reasonable confidentiality undertakings with Us.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            6.3 The scope of any examination and review by You of the use by Us of the Personal Data
                            shall be agreed in writing prior to the commencement of any such examination and review.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            6.4 In the event that the audit process determines that We are materially non-compliant with
                            our obligations under this Schedule, You may, by notice in writing, deny further access to
                            Your Data.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            6.5 To the extent permitted under Data Protection Legislation, We may demonstrate Our and,
                            if applicable Our Further Sub-processors’, compliance with Our obligations under this
                            Schedule through Our compliance with a certification scheme or code of conduct approved
                            under Data Protection Legislation.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            7. <span className={classes.infoBold}>Data Subject Requests</span>
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            7.2 We shall notify You as soon as reasonably practicable if We receive:
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            7.2.1 a request from a Data Subject for access to that person’s Personal Data (relating to
                            the Services);
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            7.2.2 any communication from a Data Subject (relating to the Services) seeking to exercise
                            rights conferred on the Data Subject by Data Protection Legislation in respect of Personal
                            Data; or
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            7.2.3 any complaint or any claim for compensation arising from or relating to the Processing
                            of such Personal Data.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            7.3 We shall not disclose Your Data to any Data Subject or to a third party other than at
                            the request of You, as provided for in this Agreement, or as required by law.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            7.4 We shall not respond to any request from a Data Subject except as required by law.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            8. <span className={classes.infoBold}>Security</span>
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            8.1 We shall, in accordance with Our requirements under Data Protection Legislation,
                            implement Appropriate Technical and Organizational Measures to safeguard Your Data from
                            unauthorized or unlawful Processing or accidental loss, alteration, disclosure, destruction
                            or damage, and that, having regard to the state of technological development and the cost of
                            implementing any measures (and the nature, scope, context and purposes of Processing, as
                            well as the risk to Data Subjects), such measures shall be proportionate and reasonable to
                            ensure a level of security appropriate to the harm that might result from unauthorized or
                            unlawful Processing or accidental loss, alteration, disclosure, destruction or damage and to
                            the nature of the Personal Data to be protected.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            8.2 We shall ensure that Your Data provided by You can only be accessed by persons and
                            systems that are authorized by Us and necessary to meet the Business Purpose, and that all
                            equipment used by Us for the Processing of Your Data shall be maintained by Us in a
                            physically secure environment.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            9. <span className={classes.infoBold}>Breach reporting</span>
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            9.1 We shall promptly inform You if any of Your Data is lost or destroyed or becomes
                            damaged, corrupted, or unusable, or if there is any accidental, unauthorized or unlawful
                            disclosure of or access to any of Your Data. In such case, We will use Our reasonable
                            endeavors to restore Your Data at Your expense (save where the incident was caused by Our
                            negligent act or omission, in which case it will be at Our expense), and will comply with
                            all of Our obligations under Data Protection Legislation in this regard.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            9.2 We must inform You of any Personal Data Breaches, or any complaint, notice or
                            communication in relation to a Personal Data Breach, without undue delay. Taking into
                            account the nature of Our Processing of the Personal Data and the information available to
                            Us and at Your cost We will provide sufficient information and assist You in ensuring
                            compliance with Your (or the Controller’s, if applicable) obligations in relation to
                            notification of Personal Data Breaches (including the obligation to notify Personal Data
                            Breaches to the DPC within seventy two (72) hours), and communication of Personal Data
                            Breaches to Data Subjects where the breach is likely to result in a high risk to the rights
                            of such Data Subjects. Taking into account the nature of Our Processing of the Personal Data
                            and the information available to Us and at Your cost, We shall co-operate with You and take
                            such reasonable commercial steps as are directed by You to assist in the investigation,
                            mitigation and remediation of each such Personal Data Breach.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            10. <span className={classes.infoBold}>Restricted transfers</span>
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            10.1 A Restricted Transfer may not be made by Us (other than transfers to our affiliates and
                            any agents and contractors for the purposes of performing the Services, and You shall
                            endeavor to obtain if You are the Controller, or procure the obtaining of if You are the
                            Processor, explicit consent from relevant Data Subjects in respect of such potential
                            transfers) without the prior written consent of You (such consent not to be unreasonably
                            withheld, delayed or conditioned), and if such consent has been obtained (or is
                            unnecessary), such Restricted Transfer may only be made where there are appropriate
                            safeguards in place with regard to the rights of Data Subjects (including but not limited to
                            the Standard Contractual Clauses, Privacy Shield, binding corporate rules, or any other
                            model clauses approved by the DPC).
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            10.2 Subject to Clause 10.3, in the event of any Restricted Transfer by Us to a contracted
                            Sub- processor, to any affiliate of You or otherwise (“Data Importer") for which your
                            consent has been obtained (or is unnecessary), We and You shall procure that: (i) You or the
                            relevant Controller (where the Restricted Transfer is being made at the request of You or
                            the relevant Controller), or Us acting as agent for and on behalf of the relevant Controller
                            (where the Restricted Transfer is being made at the request of Us), and (ii) the Data
                            Importer, shall enter into the Standard Contractual Clauses in respect of such Restricted
                            Transfer.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            10.3 Clauses 10.1 or 10.2 shall not apply to a Restricted Transfer if other compliance steps
                            (which may include, but shall not be limited to, obtaining explicit consents from Data
                            Subjects) have been taken to allow the relevant Restricted Transfer to take place without
                            breach of applicable Data Protection Legislation.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            11. <span className={classes.infoBold}>Sub-processors</span>
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            11.1 You agree and acknowledge that We may have Your Data Processed by any of Our affiliates
                            and by any agents and contractors for the purpose of providing the Service (a “Further Sub-
                            processor”). A list of the categories of Further Sub-processors used by Us may be maintained
                            on our website or may be otherwise notified to You by Us from time to time. If you object to
                            such sub-processing arrangements, then You should confirm this to Us and, if you do so
                            confirm, You acknowledge that You may no longer be able to avail of some or all of Our
                            Services.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            11.2 We must enter into a data processing contract with the Further Sub-processor which
                            places the same (or substantially similar) data protection obligations on the Sub-processor
                            as We have in this Schedule (in particular, providing sufficient guarantees to implement
                            Appropriate Technical and Organizational Measures in such a manner that the Processing will
                            meet the requirements of Data Protection Legislation).
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            11.3 With respect to each Sub-processor, We shall, before the Further Sub-processor first
                            Processes Your Data, ensure that the Further Sub-processor is capable of providing the level
                            of protection for Your Data required by this Schedule.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            11.4 We will respect the conditions for engaging Further Sub-processors as set out in
                            Article 28 (4) of the GDPR.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            12. <span className={classes.infoBold}>Warranties</span>
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            12.1 We warrant and undertake to You that We will Process Your Data in compliance with our
                            obligations under Data Protection Legislation;
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            12.2 You hereby warrant and undertake to Us that:
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            12.2.1 You have complied with and shall continue to comply with Your obligations under Data
                            Protection Legislation;
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            12.2.5 You shall not, by act or omission, cause Us to violate any Data Protection
                            Legislation, notices provided to, or consents obtained from, Data Subjects as a result of Us
                            or Our Sub- processors’ Processing the Personal Data; and
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            12.2.6 notwithstanding anything contained in this Schedule, You shall pay in immediately
                            available funds Our costs incurred or likely to be incurred, at Our option in advance under
                            this Schedule (where matters are stated to be at Your cost).
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            14. <span className={classes.infoBold}>Limitation of liability</span>
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            14.1 Unless required to do so by the DPC or any other competent supervisory authority, We
                            shall not make any payment or any offer of payment to any Data Subject in response to any
                            complaint or any claim for compensation arising from or relating to the Processing of Your
                            Data, without the prior written agreement of You.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            14.2 You acknowledge and agree that We are reliant on You for direction as to the extent to
                            which We are entitled to use and process Your Data. Consequently, We will not be liable to
                            You for any claim brought by a Data Subject or in respect of any regulatory fine or order
                            arising from any action or omission by Us, to the extent that such action or omission
                            resulted directly from Your instructions and/or the transactions contemplated by this
                            Schedule.
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            15. <span className={classes.infoBold}>Consequences of termination on Your Data</span>
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paragraph={true}>
                            15.1 Upon termination or expiry of this Agreement, at the choice of You, We shall Delete or
                            return all Your Data to You and Delete existing copies of Your Data, unless legally
                            required/entitled to store Your Data for a period of time. If You make no such election
                            within a ten (10) day period of termination or expiry of this Agreement, We may Delete any
                            of Your Data in our possession; and if You elect for destruction rather than return of Your
                            Data, We shall as soon as reasonably practicable ensure that all Your Data is Deleted from
                            Our System, unless legally required/entitled to store Your Data for a period of time.
                        </Typography>
                    </Grid>
                </Grid>
            </section>
            <section>
                <Typography
                    variant="h6"
                    align="center"
                    color="textSecondary"
                    className={classes.bodyHeader}
                    gutterBottom={true}
                    paragraph={true}
                >
                    EXHIBIT A: TERMS OF USE
                </Typography>
                <Grid container={true} direction="column" className={classes.gridSection} spacing={1}>
                    <Grid item={true} className={classes.formBox}>
                        <Typography variant="subtitle1" color="textSecondary" paragraph={true}>
                            An end customer Terms of Use statement will appear at the time of end customer provisioning.
                            The text of this agreement will be available via the Platform, and will require end customer
                            click thru to agree to the Term of Use.
                        </Typography>
                    </Grid>
                </Grid>
            </section>
            <CookiesSnackbar />
        </Layout>
    )
}

export default TrialPage
